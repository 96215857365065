import { Container } from "../Container";
import { Routes, Route } from "react-router-dom";
import { Home } from "./Home";
import { Gallery } from "./Gallery";
import { Video } from "./Video";

export function App() {
  return (
    <Container>
      <Routes>
        <Route element={<Home />} path="/" />
        <Route element={<Gallery />} path="/gallery" />
        <Route element={<Video />} path="/video" />
      </Routes>
    </Container>
  );
}
