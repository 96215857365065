import React from "react";

import PhotoAlbum from "react-photo-album";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Download from "yet-another-react-lightbox/plugins/download";

import { getImages } from "../photos";
import { Header } from "../components/Header";
import { SpinnerCenter } from "../components/SpinnerCenter";

export function Gallery() {
  const [index, setIndex] = React.useState(-1);
  const [initialImages, setInitialImages] = React.useState<any>(null);
  const [extraImages, setExtraImages] = React.useState<any>(null);

  React.useEffect(() => {
    setup();
  }, []);

  const setup = async () => {
    setInitialImages(null);
    setExtraImages(null);

    // Load a small batch.
    console.log("Loading initial");
    const initialSize = 20;
    const initialImages = await getImages(0, initialSize);
    setInitialImages(initialImages);

    // Then load the rest.
    console.log("loading extra");
    const extraImages = await getImages(initialSize, Infinity);
    setExtraImages(extraImages);
  };

  const combinedImages: any[] = [];
  if (initialImages !== null) {
    combinedImages.push(...initialImages);
  }

  if (extraImages !== null) {
    combinedImages.push(...extraImages);
  }

  return (
    <div className="max-w-5xl w-full">
      <Header />

      <div className="m-4 mb-8">
        {initialImages === null && <SpinnerCenter />}

        {initialImages !== null && (
          <>
            <PhotoAlbum
              photos={combinedImages}
              layout="masonry"
              columns={(containerWidth) => {
                if (containerWidth < 640) {
                  return 2;
                }

                return 3;
              }}
              targetRowHeight={150}
              onClick={({ index }) => setIndex(index)}
            />

            {extraImages === null && (
              <div className="flex flex-col items-center p-4">
                <SpinnerCenter />
                <div className="-m-4">Loading More Photos</div>
              </div>
            )}

            <Lightbox
              slides={combinedImages}
              open={index >= 0}
              index={index}
              close={() => setIndex(-1)}
              plugins={[Download, Fullscreen, Slideshow, Zoom]}
            />
          </>
        )}
      </div>
    </div>
  );
}
