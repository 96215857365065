import React from "react";

import { Popover, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import names from "../img/names.png";
import { Link } from "react-router-dom";

export function Header() {
  const navOptions = [
    {
      title: "Gallery",
      url: "/gallery",
    },
    {
      title: "Video",
      url: "/video",
    },
  ];

  return (
    <div className="sticky top-0 bg-white/90 px-8 py-4 mb-4 z-10">
      <Popover>
        <nav
          className="relative mx-auto flex max-w-7xl items-center justify-between"
          aria-label="Global"
        >
          <div className="flex flex-1 items-center">
            <div className="flex w-full items-center justify-between md:w-auto">
              <Link to="/">
                <span className="sr-only">Emmalene + James</span>
                <img
                  className="max-h-14 w-auto sm:max-h-18 pr-4"
                  src={names}
                  alt=""
                />
              </Link>
              <div className="flex items-center md:hidden">
                <Popover.Button className="focus-ring-inset inline-flex items-center justify-center rounded-md bg-gray-200 p-2 text-gray-800 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
          </div>
          <div className="hidden space-x-10 md:flex">
            {navOptions.map((navOption) => {
              return (
                <Link
                  key={navOption.title}
                  to={navOption.url}
                  className="font-medium text-gray-700 hover:text-gray-500"
                >
                  {navOption.title}
                </Link>
              );
            })}
          </div>
        </nav>

        <Transition
          as={React.Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute inset-x-0 top-0 z-20 origin-top-right transform p-2 transition md:hidden"
          >
            <div className="overflow-hidden rounded-lg bg-white shadow-md ring-1 ring-black ring-opacity-5">
              <div className="flex items-center justify-between px-5 pt-4">
                <div>
                  <img className="max-h-16 w-auto pr-4" src={names} alt="" />
                </div>
                <div className="">
                  <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-yellow-500">
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="space-y-1 px-2 pt-2 pb-3">
                {navOptions.map((navOption) => (
                  <Link
                    key={navOption.title}
                    to={navOption.url}
                    className="block rounded-md px-3 py-2 text-base font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900"
                  >
                    {navOption.title}
                  </Link>
                ))}
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  );
}
