import { getImageSize } from "react-image-size";

const photoFileNames = [
  "220806EJ_Wedding_Photos_003.jpg",
  "220806EJ_Wedding_Photos_013.jpg",
  "220806EJ_Wedding_Photos_015.jpg",
  "220806EJ_Wedding_Photos_017.jpg",
  "220806EJ_Wedding_Photos_018.jpg",
  "220806EJ_Wedding_Photos_019.jpg",
  "220806EJ_Wedding_Photos_020.jpg",
  "220806EJ_Wedding_Photos_021.jpg",
  "220806EJ_Wedding_Photos_022.jpg",
  "220806EJ_Wedding_Photos_023.jpg",
  "220806EJ_Wedding_Photos_025.jpg",
  "220806EJ_Wedding_Photos_026.jpg",
  "220806EJ_Wedding_Photos_027.jpg",
  "220806EJ_Wedding_Photos_028.jpg",
  "220806EJ_Wedding_Photos_029.jpg",
  "220806EJ_Wedding_Photos_041.jpg",
  "220806EJ_Wedding_Photos_042.jpg",
  "220806EJ_Wedding_Photos_048.jpg",
  "220806EJ_Wedding_Photos_051.jpg",
  "220806EJ_Wedding_Photos_057.jpg",
  "220806EJ_Wedding_Photos_058.jpg",
  "220806EJ_Wedding_Photos_068.jpg",
  "220806EJ_Wedding_Photos_069.jpg",
  "220806EJ_Wedding_Photos_070.jpg",
  "220806EJ_Wedding_Photos_071.jpg",
  "220806EJ_Wedding_Photos_072.jpg",
  "220806EJ_Wedding_Photos_076.jpg",
  "220806EJ_Wedding_Photos_078.jpg",
  "220806EJ_Wedding_Photos_093.jpg",
  "220806EJ_Wedding_Photos_094.jpg",
  "220806EJ_Wedding_Photos_098.jpg",
  "220806EJ_Wedding_Photos_101.jpg",
  "220806EJ_Wedding_Photos_102.jpg",
  "220806EJ_Wedding_Photos_104.jpg",
  "220806EJ_Wedding_Photos_105.jpg",
  "220806EJ_Wedding_Photos_106.jpg",
  "220806EJ_Wedding_Photos_107.jpg",
  "220806EJ_Wedding_Photos_110.jpg",
  "220806EJ_Wedding_Photos_112.jpg",
  "220806EJ_Wedding_Photos_117.jpg",
  "220806EJ_Wedding_Photos_120.jpg",
  "220806EJ_Wedding_Photos_123.jpg",
  "220806EJ_Wedding_Photos_126.jpg",
  "220806EJ_Wedding_Photos_127.jpg",
  "220806EJ_Wedding_Photos_130.jpg",
  "220806EJ_Wedding_Photos_131.jpg",
  "220806EJ_Wedding_Photos_133.jpg",
  "220806EJ_Wedding_Photos_135.jpg",
  "220806EJ_Wedding_Photos_136.jpg",
  "220806EJ_Wedding_Photos_137.jpg",
  "220806EJ_Wedding_Photos_141.jpg",
  "220806EJ_Wedding_Photos_142.jpg",
  "220806EJ_Wedding_Photos_143.jpg",
  "220806EJ_Wedding_Photos_146.jpg",
  "220806EJ_Wedding_Photos_147.jpg",
  "220806EJ_Wedding_Photos_148.jpg",
  "220806EJ_Wedding_Photos_149.jpg",
  "220806EJ_Wedding_Photos_150.jpg",
  "220806EJ_Wedding_Photos_151.jpg",
  "220806EJ_Wedding_Photos_152.jpg",
  "220806EJ_Wedding_Photos_153.jpg",
  "220806EJ_Wedding_Photos_154.jpg",
  "220806EJ_Wedding_Photos_155.jpg",
  "220806EJ_Wedding_Photos_157.jpg",
  "220806EJ_Wedding_Photos_160.jpg",
  "220806EJ_Wedding_Photos_161.jpg",
  "220806EJ_Wedding_Photos_163.jpg",
  "220806EJ_Wedding_Photos_164.jpg",
  "220806EJ_Wedding_Photos_165.jpg",
  "220806EJ_Wedding_Photos_166.jpg",
  "220806EJ_Wedding_Photos_167.jpg",
  "220806EJ_Wedding_Photos_168.jpg",
  "220806EJ_Wedding_Photos_169.jpg",
  "220806EJ_Wedding_Photos_170.jpg",
  "220806EJ_Wedding_Photos_171.jpg",
  "220806EJ_Wedding_Photos_172.jpg",
  "220806EJ_Wedding_Photos_173.jpg",
  "220806EJ_Wedding_Photos_174.jpg",
  "220806EJ_Wedding_Photos_175.jpg",
  "220806EJ_Wedding_Photos_176.jpg",
  "220806EJ_Wedding_Photos_178.jpg",
  "220806EJ_Wedding_Photos_179.jpg",
  "220806EJ_Wedding_Photos_180.jpg",
  "220806EJ_Wedding_Photos_181.jpg",
  "220806EJ_Wedding_Photos_182.jpg",
  "220806EJ_Wedding_Photos_183.jpg",
  "220806EJ_Wedding_Photos_184.jpg",
  "220806EJ_Wedding_Photos_185.jpg",
  "220806EJ_Wedding_Photos_186.jpg",
  "220806EJ_Wedding_Photos_187.jpg",
  "220806EJ_Wedding_Photos_188.jpg",
  "220806EJ_Wedding_Photos_189.jpg",
  "220806EJ_Wedding_Photos_190.jpg",
  "220806EJ_Wedding_Photos_191.jpg",
  "220806EJ_Wedding_Photos_192.jpg",
  "220806EJ_Wedding_Photos_193.jpg",
  "220806EJ_Wedding_Photos_194.jpg",
  "220806EJ_Wedding_Photos_195.jpg",
  "220806EJ_Wedding_Photos_196.jpg",
  "220806EJ_Wedding_Photos_197.jpg",
  "220806EJ_Wedding_Photos_198.jpg",
  "220806EJ_Wedding_Photos_199.jpg",
  "220806EJ_Wedding_Photos_200.jpg",
  "220806EJ_Wedding_Photos_201.jpg",
  "220806EJ_Wedding_Photos_202.jpg",
  "220806EJ_Wedding_Photos_203.jpg",
  "220806EJ_Wedding_Photos_204.jpg",
  "220806EJ_Wedding_Photos_205.jpg",
  "220806EJ_Wedding_Photos_206.jpg",
  "220806EJ_Wedding_Photos_207.jpg",
  "220806EJ_Wedding_Photos_208.jpg",
  "220806EJ_Wedding_Photos_209.jpg",
  "220806EJ_Wedding_Photos_210.jpg",
  "220806EJ_Wedding_Photos_211.jpg",
  "220806EJ_Wedding_Photos_212.jpg",
  "220806EJ_Wedding_Photos_213.jpg",
  "220806EJ_Wedding_Photos_214.jpg",
  "220806EJ_Wedding_Photos_215.jpg",
  "220806EJ_Wedding_Photos_216.jpg",
  "220806EJ_Wedding_Photos_217.jpg",
  "220806EJ_Wedding_Photos_218.jpg",
  "220806EJ_Wedding_Photos_219.jpg",
  "220806EJ_Wedding_Photos_220.jpg",
  "220806EJ_Wedding_Photos_221.jpg",
  "220806EJ_Wedding_Photos_222.jpg",
  "220806EJ_Wedding_Photos_223.jpg",
  "220806EJ_Wedding_Photos_224.jpg",
  "220806EJ_Wedding_Photos_229.jpg",
  "220806EJ_Wedding_Photos_230.jpg",
  "220806EJ_Wedding_Photos_231.jpg",
  "220806EJ_Wedding_Photos_232.jpg",
  "220806EJ_Wedding_Photos_233.jpg",
  "220806EJ_Wedding_Photos_234.jpg",
  "220806EJ_Wedding_Photos_237.jpg",
  "220806EJ_Wedding_Photos_238.jpg",
  "220806EJ_Wedding_Photos_241.jpg",
  "220806EJ_Wedding_Photos_242.jpg",
  "220806EJ_Wedding_Photos_243.jpg",
  "220806EJ_Wedding_Photos_245.jpg",
  "220806EJ_Wedding_Photos_248.jpg",
  "220806EJ_Wedding_Photos_249.jpg",
  "220806EJ_Wedding_Photos_250.jpg",
  "220806EJ_Wedding_Photos_251.jpg",
  "220806EJ_Wedding_Photos_252.jpg",
  "220806EJ_Wedding_Photos_254.jpg",
  "220806EJ_Wedding_Photos_255.jpg",
  "220806EJ_Wedding_Photos_256.jpg",
  "220806EJ_Wedding_Photos_257.jpg",
  "220806EJ_Wedding_Photos_258.jpg",
  "220806EJ_Wedding_Photos_259.jpg",
  "220806EJ_Wedding_Photos_260.jpg",
  "220806EJ_Wedding_Photos_261.jpg",
  "220806EJ_Wedding_Photos_262.jpg",
  "220806EJ_Wedding_Photos_263.jpg",
  "220806EJ_Wedding_Photos_264.jpg",
  "220806EJ_Wedding_Photos_265.jpg",
  "220806EJ_Wedding_Photos_269.jpg",
  "220806EJ_Wedding_Photos_270.jpg",
  "220806EJ_Wedding_Photos_273.jpg",
  "220806EJ_Wedding_Photos_275.jpg",
  "220806EJ_Wedding_Photos_278.jpg",
  "220806EJ_Wedding_Photos_281.jpg",
  "220806EJ_Wedding_Photos_282.jpg",
  "220806EJ_Wedding_Photos_283.jpg",
  "220806EJ_Wedding_Photos_284.jpg",
  "220806EJ_Wedding_Photos_285.jpg",
  "220806EJ_Wedding_Photos_288.jpg",
  "220806EJ_Wedding_Photos_289.jpg",
  "220806EJ_Wedding_Photos_290.jpg",
  "220806EJ_Wedding_Photos_293.jpg",
  "220806EJ_Wedding_Photos_295.jpg",
  "220806EJ_Wedding_Photos_297.jpg",
  "220806EJ_Wedding_Photos_300.jpg",
  "220806EJ_Wedding_Photos_302.jpg",
  "220806EJ_Wedding_Photos_303.jpg",
  "220806EJ_Wedding_Photos_304.jpg",
  "220806EJ_Wedding_Photos_306.jpg",
  "220806EJ_Wedding_Photos_307.jpg",
  "220806EJ_Wedding_Photos_308.jpg",
  "220806EJ_Wedding_Photos_309.jpg",
  "220806EJ_Wedding_Photos_312.jpg",
  "220806EJ_Wedding_Photos_314.jpg",
  "220806EJ_Wedding_Photos_315.jpg",
  "220806EJ_Wedding_Photos_316.jpg",
  "220806EJ_Wedding_Photos_317.jpg",
  "220806EJ_Wedding_Photos_318.jpg",
  "220806EJ_Wedding_Photos_319.jpg",
  "220806EJ_Wedding_Photos_320.jpg",
  "220806EJ_Wedding_Photos_321.jpg",
  "220806EJ_Wedding_Photos_323.jpg",
  "220806EJ_Wedding_Photos_324.jpg",
  "220806EJ_Wedding_Photos_325.jpg",
  "220806EJ_Wedding_Photos_326.jpg",
  "220806EJ_Wedding_Photos_327.jpg",
  "220806EJ_Wedding_Photos_328.jpg",
  "220806EJ_Wedding_Photos_329.jpg",
  "220806EJ_Wedding_Photos_330.jpg",
  "220806EJ_Wedding_Photos_331.jpg",
  "220806EJ_Wedding_Photos_332.jpg",
  "220806EJ_Wedding_Photos_333.jpg",
  "220806EJ_Wedding_Photos_334.jpg",
  "220806EJ_Wedding_Photos_335.jpg",
  "220806EJ_Wedding_Photos_337.jpg",
  "220806EJ_Wedding_Photos_338.jpg",
  "220806EJ_Wedding_Photos_341.jpg",
  "220806EJ_Wedding_Photos_342.jpg",
  "220806EJ_Wedding_Photos_344.jpg",
  "220806EJ_Wedding_Photos_345.jpg",
  "220806EJ_Wedding_Photos_346.jpg",
  "220806EJ_Wedding_Photos_347.jpg",
  "220806EJ_Wedding_Photos_348.jpg",
  "220806EJ_Wedding_Photos_349.jpg",
  "220806EJ_Wedding_Photos_350.jpg",
  "220806EJ_Wedding_Photos_351.jpg",
  "220806EJ_Wedding_Photos_352.jpg",
  "220806EJ_Wedding_Photos_353.jpg",
  "220806EJ_Wedding_Photos_354.jpg",
  "220806EJ_Wedding_Photos_355.jpg",
  "220806EJ_Wedding_Photos_356.jpg",
  "220806EJ_Wedding_Photos_358.jpg",
  "220806EJ_Wedding_Photos_359.jpg",
  "220806EJ_Wedding_Photos_360.jpg",
  "220806EJ_Wedding_Photos_361.jpg",
  "220806EJ_Wedding_Photos_362.jpg",
  "220806EJ_Wedding_Photos_364.jpg",
  "220806EJ_Wedding_Photos_365.jpg",
  "220806EJ_Wedding_Photos_366.jpg",
  "220806EJ_Wedding_Photos_369.jpg",
  "220806EJ_Wedding_Photos_370.jpg",
  "220806EJ_Wedding_Photos_373.jpg",
  "220806EJ_Wedding_Photos_375.jpg",
  "220806EJ_Wedding_Photos_376.jpg",
  "220806EJ_Wedding_Photos_378.jpg",
  "220806EJ_Wedding_Photos_379.jpg",
  "220806EJ_Wedding_Photos_380.jpg",
  "220806EJ_Wedding_Photos_382.jpg",
  "220806EJ_Wedding_Photos_383.jpg",
  "220806EJ_Wedding_Photos_384.jpg",
  "220806EJ_Wedding_Photos_387.jpg",
  "220806EJ_Wedding_Photos_388.jpg",
  "220806EJ_Wedding_Photos_389.jpg",
  "220806EJ_Wedding_Photos_390.jpg",
  "220806EJ_Wedding_Photos_391.jpg",
  "220806EJ_Wedding_Photos_392.jpg",
  "220806EJ_Wedding_Photos_393.jpg",
  "220806EJ_Wedding_Photos_394.jpg",
  "220806EJ_Wedding_Photos_395.jpg",
  "220806EJ_Wedding_Photos_396.jpg",
  "220806EJ_Wedding_Photos_397.jpg",
  "220806EJ_Wedding_Photos_398.jpg",
  "220806EJ_Wedding_Photos_399.jpg",
  "220806EJ_Wedding_Photos_400.jpg",
  "220806EJ_Wedding_Photos_401.jpg",
  "220806EJ_Wedding_Photos_402.jpg",
  "220806EJ_Wedding_Photos_403.jpg",
  "220806EJ_Wedding_Photos_404.jpg",
  "220806EJ_Wedding_Photos_405.jpg",
  "220806EJ_Wedding_Photos_406.jpg",
  "220806EJ_Wedding_Photos_407.jpg",
  "220806EJ_Wedding_Photos_409.jpg",
  "220806EJ_Wedding_Photos_410.jpg",
  "220806EJ_Wedding_Photos_411.jpg",
  "220806EJ_Wedding_Photos_412.jpg",
  "220806EJ_Wedding_Photos_413.jpg",
  "220806EJ_Wedding_Photos_414.jpg",
  "220806EJ_Wedding_Photos_415.jpg",
  "220806EJ_Wedding_Photos_416.jpg",
  "220806EJ_Wedding_Photos_417.jpg",
  "220806EJ_Wedding_Photos_418.jpg",
  "220806EJ_Wedding_Photos_419.jpg",
  "220806EJ_Wedding_Photos_420.jpg",
  "220806EJ_Wedding_Photos_423.jpg",
  "220806EJ_Wedding_Photos_425.jpg",
  "220806EJ_Wedding_Photos_426.jpg",
  "220806EJ_Wedding_Photos_427.jpg",
  "220806EJ_Wedding_Photos_428.jpg",
  "220806EJ_Wedding_Photos_430.jpg",
  "220806EJ_Wedding_Photos_432.jpg",
  "220806EJ_Wedding_Photos_433.jpg",
  "220806EJ_Wedding_Photos_434.jpg",
  "220806EJ_Wedding_Photos_435.jpg",
  "220806EJ_Wedding_Photos_436.jpg",
  "220806EJ_Wedding_Photos_437.jpg",
  "220806EJ_Wedding_Photos_438.jpg",
  "220806EJ_Wedding_Photos_439.jpg",
  "220806EJ_Wedding_Photos_440.jpg",
  "220806EJ_Wedding_Photos_441.jpg",
  "220806EJ_Wedding_Photos_442.jpg",
  "220806EJ_Wedding_Photos_443.jpg",
  "220806EJ_Wedding_Photos_444.jpg",
  "220806EJ_Wedding_Photos_445.jpg",
  "220806EJ_Wedding_Photos_446.jpg",
  "220806EJ_Wedding_Photos_447.jpg",
  "220806EJ_Wedding_Photos_448.jpg",
  "220806EJ_Wedding_Photos_449.jpg",
  "220806EJ_Wedding_Photos_450.jpg",
  "220806EJ_Wedding_Photos_451.jpg",
  "220806EJ_Wedding_Photos_452.jpg",
  "220806EJ_Wedding_Photos_453.jpg",
  "220806EJ_Wedding_Photos_454.jpg",
  "220806EJ_Wedding_Photos_455.jpg",
  "220806EJ_Wedding_Photos_456.jpg",
  "220806EJ_Wedding_Photos_457.jpg",
  "220806EJ_Wedding_Photos_458.jpg",
  "220806EJ_Wedding_Photos_459.jpg",
  "220806EJ_Wedding_Photos_460.jpg",
  "220806EJ_Wedding_Photos_461.jpg",
  "220806EJ_Wedding_Photos_462.jpg",
  "220806EJ_Wedding_Photos_463.jpg",
  "220806EJ_Wedding_Photos_464.jpg",
  "220806EJ_Wedding_Photos_465.jpg",
  "220806EJ_Wedding_Photos_466.jpg",
  "220806EJ_Wedding_Photos_467.jpg",
  "220806EJ_Wedding_Photos_468.jpg",
  "220806EJ_Wedding_Photos_469.jpg",
  "220806EJ_Wedding_Photos_470.jpg",
  "220806EJ_Wedding_Photos_471.jpg",
  "220806EJ_Wedding_Photos_472.jpg",
  "220806EJ_Wedding_Photos_474.jpg",
  "220806EJ_Wedding_Photos_475.jpg",
  "220806EJ_Wedding_Photos_477.jpg",
  "220806EJ_Wedding_Photos_478.jpg",
  "220806EJ_Wedding_Photos_479.jpg",
  "220806EJ_Wedding_Photos_480.jpg",
  "220806EJ_Wedding_Photos_481.jpg",
  "220806EJ_Wedding_Photos_482.jpg",
  "220806EJ_Wedding_Photos_483.jpg",
  "220806EJ_Wedding_Photos_484.jpg",
  "220806EJ_Wedding_Photos_485.jpg",
  "220806EJ_Wedding_Photos_486.jpg",
  "220806EJ_Wedding_Photos_487.jpg",
  "220806EJ_Wedding_Photos_488.jpg",
  "220806EJ_Wedding_Photos_489.jpg",
  "220806EJ_Wedding_Photos_490.jpg",
  "220806EJ_Wedding_Photos_491.jpg",
  "220806EJ_Wedding_Photos_492.jpg",
  "220806EJ_Wedding_Photos_493.jpg",
  "220806EJ_Wedding_Photos_494.jpg",
  "220806EJ_Wedding_Photos_495.jpg",
  "220806EJ_Wedding_Photos_496.jpg",
];

export const getImages = async (start: number, count: number) => {
  console.log(`${start} - get ${count}`);

  const photoFileNamesFiltered = photoFileNames.slice(start, start + count);

  return await Promise.all(
    photoFileNamesFiltered.map(async (filename, inx) => {
      // console.log(`download ${inx} ${filename}`);

      const img = require(`./img/${filename}`);

      const { width, height } = await getImageSize(img);

      return {
        src: img,
        width: width,
        height: height,
      };
    })
  );
};
