import title from "../img/title.png";
import { Link } from "react-router-dom";

export function Home() {
  return (
    <div className="flex justify-center items-center flex-col">
      <img
        src={title}
        alt="The Wedding of Emmalene and James"
        className="w-full max-w-xl p-10"
      />

      <div className="flex flex-col sm:flex-row justify-center gap-4 m-4">
        <Link
          to="/gallery"
          className="inline-flex items-center rounded-md border border-gray-300 bg-white px-6 py-3 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
        >
          View Gallery
        </Link>
        <Link
          to="/video"
          className="inline-flex items-center rounded-md border border-gray-300 bg-white px-6 py-3 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
        >
          Watch Video
        </Link>
      </div>
    </div>
  );
}
