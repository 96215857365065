import React from "react";

import flowerLeft from "./img/flower-left.png";
import flowerRight from "./img/flower-right.png";

type Props = {
  children?: React.ReactNode;
};

export const Container: React.FC<Props> = ({ children }) => {
  return (
    <div>
      <div className="flex justify-center w-full min-h-screen">{children}</div>

      <div className="fixed top-0 left-0 w-screen min-h-screen overflow-hidden flex -z-50">
        <div className="absolute top-12 -left-4 md:left-0">
          <img src={flowerLeft} className="w-24 md:w-32" />
        </div>

        <div className="absolute bottom-0 right-0">
          <img src={flowerRight} className="w-24 md:w-32" />
        </div>
      </div>
    </div>
  );
};
