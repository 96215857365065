import React from "react";
import { Header } from "../components/Header";
import { SpinnerCenter } from "../components/SpinnerCenter";

export function Video() {
  const [videoLoaded, setVideoLoaded] = React.useState(false);

  const videoClassName = videoLoaded
    ? "w-full aspect-video"
    : "w-full aspect-video hidden";

  return (
    <div className="max-w-5xl w-full">
      <Header />

      {!videoLoaded && <SpinnerCenter />}

      <div className="m-4 mb-8">
        <iframe
          className={videoClassName}
          src="https://www.youtube-nocookie.com/embed/6fG85o39Ilc"
          title="Emmalene + James"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          onLoad={() => {
            setVideoLoaded(true);
          }}
        />
      </div>
    </div>
  );
}
